import { config } from "config";

export const environment = {
  production: true,
  apiURL: config.diamondworxApiUrl,
  syncFusionLicense: config.syncFusionLicense,
  company: 'diamondworx',
  iconLogo: 'assets/images/logo/logo_big.png',
  coverLogo: 'assets/images/logo/logo_cover.svg',
  theme: 'theme-default',
};
